<template>
	<section>
		<b-row class="mb-2">
		    <b-col>
				<Breadcrumb :labels="labels" />				
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active class="overview-tab">
			            	<validation-observer
						        ref="form_rel"
						        #default="{invalid}" >	
				                <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col>
							            	<div class="text-right mb-2">
							            		<template v-if="$route.name == 'staff-management-edit'">

							            			<template v-if="(userData && permission.edit && detail.permission > 0)">

								            			<b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
									                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction" >
									                        Save
									                    </b-button>
									                    
									                </template>
									                <template v-else>
									                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
									                        Edit
									                    </b-button>
									                </template>

							            		</template>
							            		<template v-else>
							            			<template v-if="(userData && permission.edit && detail.permission > 0)">
							            				<b-button type="button" variant="primary" class="btn-df size-18 black" @click="toEdit(detail.id)">
									                        Edit
									                    </b-button>
							            			</template>         
							            			<template v-else>
							            				<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
									                        Edit
									                    </b-button>
							            			</template>

							            		</template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">              
				                      	<b-row style="width: 100%; margin: 0;">   
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="STID" label-cols-lg="4" class="star-required input-group-label">
				                                    <b-form-input
				                                    	v-model="detail.id"
				                                      	placeholder="Staff ID"
				                                      	readonly
				                                      	disabled
				                                    ></b-form-input>
				                              	</b-form-group>
				                          	</b-col>	
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Username" label-cols-lg="4" class="input-group-label">
													<b-form-input
														v-model="detail.username"
														placeholder="Username"
														:formatter="length50"
														:disabled="isDisabled"
													></b-form-input>
				                              	</b-form-group>
				                          	</b-col>	
				                          	<b-col lg="6" class="px-0" v-if="is_show">             
                                              	<b-form-group label="Password" label-cols-lg="4">

                                                    <b-button type="button" variant="primary" class="btn-200 mr-10" @click="changePassAction" style="max-width: 100%; ">Change Password</b-button>

                                              	</b-form-group>
                                          	</b-col>                      	
				                          	<b-col lg="12" class="px-0">
				                          		<div style="border-top: 1px solid #B1B1B1; height: 20px; "></div>
					                            <b-form-group label="Staff Name" label-cols-lg="2" class="star-required input-group-label">
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Staff Name"
						                              	rules="required"               
						                            >     
						                            	<b-form-input
						                            		v-model="detail.full_name"
					                                      	placeholder="Name"
					                                      	:formatter="length50"
															:disabled="isDisabled"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>   
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Email" label-cols-lg="2" class="star-required input-group-label">
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Email"
						                              	rules="required"                                  
						                            >     
						                            	<b-form-input
						                            		v-model="detail.email"
					                                      	placeholder="Email"
					                                      	type="email"
															:disabled="isDisabled"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>   
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Role" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Role"
						                              	rules="required"                 
						                            >     
						                                <v-select
						                                	v-model="selectedRole"
						                                    label="name"
						                                    :options="selectRole"
						                                    placeholder="Select Role"
															:disabled="isDisabled"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Status"
						                              	rules="required"               
						                            >     
						                                <v-select
						                                	v-model="selectedStatus"
						                                    label="title"
						                                    :options="selectStatus"
						                                    placeholder="Select Status"
															:disabled="isDisabled"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	
				                      	</b-row>
					                </div>
					                <b-modal id="modalProfile" ref="change_modal" title="Change Password" hide-header-close ok-title="Save">
	                                    <b-row>	                                        
	                                        <b-col lg="12" class="px-0">
	                                            <b-form-group label="New Password" label-cols-xl="4"
	                                              label-cols-md="4" label-class="text-lg-right bold" class="input-group-label">
	                                              <validation-provider
	                                                #default="{ errors }"
	                                                rules="required|min:12"
	                                                name="password"
	                                                vid="password"
	                                              >
	                                                <b-form-input
	                                                  placeholder="New Password"
	                                                  v-model="formPW.new_password"
	                                                  type="password"
	                                                  :formatter="length50"
													  :disabled="isDisabled"
	                                                ></b-form-input>
	                                                <small class="text-danger">{{ errors[0] }}</small>
	                                                
	                                              </validation-provider>
	                                            </b-form-group>
	                                        </b-col>
	                                        <b-col lg="12" class="px-0">
	                                            <b-form-group label="Confirm New Password" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold" class="input-group-label">
	                                                <validation-provider
	                                                  #default="{ errors }"
	                                                  rules="required|confirmed:password"
	                                                  name="Confirm Password"
	                                                >
	                                                  <b-form-input
	                                                    id="input-confirmPass"
	                                                    placeholder="Confirm New Password"
	                                                    v-model="formPW.confirm_new_password"
	                                                    type="password"
														:disabled="isDisabled"
	                                                  ></b-form-input>
	                                                  <small class="text-danger">{{ errors[0] }}</small>
	                                                </validation-provider>
	                                            </b-form-group>
	                                        </b-col>
	                                        <b-col>
	                                            <div class="size-14 size-12-xl bold">
	                                              At least 12 characters and contain characters from at least two (2) of the following four (4) categories:
	                                              <ul>
	                                                  <li>Upper case (A through Z)</li>
	                                                  <li>Lower case (a through z)</li>
	                                                  <li>Digits (0-9)</li>
	                                                  <li>Special characters (!,$,#,%, etc)</li>
	                                              </ul>
	                                            </div>    
	                                        </b-col>
	                                    </b-row>     
	                                    <template #modal-footer="{ cancel }">
	                                        <b-button size="sm" variant="static" class="btn-df size-18 black" @click="cancelPass" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) ">
	                                          Cancel
	                                        </b-button>
	                                        <b-button size="sm" variant="primary" class="btn-df size-18 black" @click="saveNewPass">
	                                          Save
	                                        </b-button>                        
	                                    </template>        
	                                </b-modal>
					            </b-form> 
					        </validation-observer>
			            </b-tab>                    
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '../partial/Breadcrumb'
	import Datepicker from 'vuejs-datepicker'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import globalAdmin from '../model/globalAdmin'
	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
	  		Datepicker,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "staff-management-edit": "" },                
			    detail: [],
				isDisabled: true,
		        isEditing: true,
            	is_show: false,
				permission: [],
			    formPW: {
                  	new_password: null,
                  	confirm_new_password: null
                },

			    selectStatus: [
			    	{title: 'Active', value: 1},
			    	{title: 'Inactive', value: 0},
			    ],
			    selectedStatus: {
			    	title: null, value: null
			    },

			    selectRole: [],
			    selectedRole: [],

			    new_password: null,
			    re_new_pass: null
            }
		},	
		watch: {
			"$route": function(val) {
				if(val){
					this.featchItem()
				}
			},
		},
		created(){
			if(this.$route.name == 'staff-management-detail'){
				this.labels = {'staff-management-detail': ''}
			} 
			this.permission = this.checkPermission(this.userData,"Staff Management");
			if(this.$route.name == 'staff-management-edit' && this.userData && this.permission.edit ){
				this.isDisabled = false
			}
			this.featchItem()
			this.listRole()
		},
		methods: {
			length50(e){
	          	return String(e).substring(0,50)
	      	},
	      	length100(e){
	          	return String(e).substring(0,100)
	      	}, 
			featchItem(){
	            this.$store
	            .dispatch('auth/detailStaff', {
	              	id: this.$route.params.id  
	            })
	            .then(response => {
	              	this.detail = response.data.data
	              	this.labels['staff-management-edit'] = response.data.data.full_name
	              	document.title = response.data.data.full_name + ' | Organization - ITEES | Your Global Partner of Choice'	

	              	this.labels['staff-management-detail'] = response.data.data.full_name

	              	if(this.detail.active == 1){
	              		this.selectedStatus.title = 'Active'
	              		this.selectedStatus.value = 1
	              	}
	              	else if(this.detail.active == 0){
	              		this.selectedStatus.title = 'Inactive'
	              		this.selectedStatus.value = 0
	              	}

	               	if(this.detail.role){
	               		this.selectedRole = this.detail.role
	               	}
					if(this.detail.permission === "1" && this.$route.name != 'staff-management-detail'){
						this.isDisabled = false
						this.is_show = true
					}
	            })
	            .catch((error) => {
	              console.log(error)
	            })
	        },
	        listRole(){
				this.$store
				.dispatch('auth/role_list', {})
				.then(response => {
					this.selectRole = response.data.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			editAction(){
		        this.isEditing = false
		        this.is_show = true
			},
			cancelAction(){
				this.$router.push({ name: 'staff-management' })
			},
			saveAction(){
				this.$refs.form_rel.validate().then(success => {
					let roleId = this.selectedRole.length > 0 ? this.selectedRole[0].id : this.selectedRole.id
                	if(success){
                		let datas = {
		                    username: this.detail.username,
		                    full_name: this.detail.full_name,
		                    email: this.detail.email,
		                    new_password: this.new_password,
		                    confirm_new_password: this.re_new_pass,
		                    status: this.selectedStatus ? this.selectedStatus.value : null,
		                    group_id: 1,
		                    role_ids: roleId,
		                }
		                // console.log('datas: ', datas)
		                this.$store
			                .dispatch('auth/editStaff', {
			                	id: this.$route.params.id,
			                	data: datas
			                })
			                .then(response => {
			                    this.$toast({
			                        component: ToastificationContent,
			                        props: {
			                            title: this.detail.full_name + ' Updated!',
			                            variant: 'success',
			                        },
			                    },
			                    {
			                        position: 'top-center'
			                    })

			                	this.$router.push({ name: 'staff-management' })
			                	
			                })
			                .catch((error) => {
			                  	console.log(error)
			                  	this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                        	title: error.response.data.message,
			                        	variant: 'danger',
			                      	},
			                    },
			                    {
			                      position: 'top-center'
			                    })
			                }) 
                	}
                	else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
                })
			},
			changePassAction(){
              	this.$refs['change_modal'].show()
          	},
          	cancelPass(){
              	this.formPW.new_password = null
              	this.formPW.confirm_new_password = null  
				this.$refs['change_modal'].hide() 
          	},
          	saveNewPass(){
          		this.new_password = this.formPW.new_password
          		this.re_new_pass = this.formPW.confirm_new_password
          		this.$refs['change_modal'].hide() 
          		this.saveAction()
          	},
          	toEdit(id){
				this.$router.push(`/admin/staff-management/edit/${id}`)
				if(this.detail.permission == 1){
					this.isDisabled = false
					this.is_show = true
				}
			}
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	 #modalProfile .modal-dialog .modal-header{
        justify-content: center;
        padding-top: 0;
    }
    #modalProfile .modal-dialog .modal-header h5{
        font-size: 24px;
        color: black;
    }
    #modalProfile .modal-dialog .modal-footer{
        justify-content: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border: 0; 
    }
    #modalProfile .modal-dialog .modal-content{
      padding: 50px 30px; 
    }    
    #modalProfile .modal-footer button{
        border-radius: 5px;
        border: 0;
    }
    #modalProfile .modal-footer button.btn-secondary{
        box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
        border-radius: 5px;
        border: 0;
        color: #442E2E !important;
        background-color: #fff !important;
    }
    #modalProfile .modal-footer button.btn-primary{
        color: #fff !important
    }
    .Vue-Toastification__container.top-center .toastification *{
       text-align: center !important;
    }
    @media only screen and (min-width: 1600px){
      #modalProfile .modal-dialog{
          margin-top: 127px; 
      }
    }
    @media only screen and (min-width: 1440px){
        #modalProfile .modal-dialog .col-form-label.col-xl-4 {
            flex: 0 0 33%;
            max-width: 33%;
        }
    }
    @media only screen and (max-width: 1400px){
      #modalProfile .modal-dialog .modal-content {
          padding: 30px;
      }
    }
    @media only screen and (min-width: 992px) {
        .frm-basic div.d-lg-flex > div{
          width: 50%; 
        }
    }
    @media only screen and (min-width: 635px){
        #modalProfile .modal-dialog {
            max-width: 635px;
        }
    }  

</style>